import React from "react";
import Stats from "../components/Stats";
import { FaGlobe, FaLaptop, FaCube } from "react-icons/fa";

const statsData = [
  { name: "Jaar ervaring", amount: "2", icon: <FaGlobe /> },
  { name: "Bezoekers website", amount: "1,6K", icon: <FaLaptop /> },
  { name: "Afgewerkte projecten", amount: "27", icon: <FaCube /> },
];

const StatsSection = () => (
  <div className="stats-section">
    {statsData.map((stat, index) => (
      <Stats key={index} {...stat} />
    ))}
  </div>
);

export default StatsSection;
